import React from "react"
import moment from "moment"
import css from "@styled-system/css"
import { graphql, Link } from "gatsby"
import { mdiFacebook, mdiTwitter, mdiLinkedin } from "@mdi/js"

import {
  Container,
  Box,
  Flex,
  Button,
  H1,
  H2,
  Icon,
  Text,
  H3,
  H6,
  SEO,
  PostCard,
} from "components"

const EmptyVector = () => (
  <svg
    width="60%"
    height="60%"
    viewBox="0 0 44 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={css({
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    })}
  >
    <path
      d="M24.1062 58.8928C19.8476 62.4808 15.5202 66.1612 15.5202 66.1612H13.7999C13.7999 66.1612 18.9301 61.8033 24.1138 57.4299V58.8928H24.1062Z"
      fill="#595C5B"
    />
    <path
      d="M24.1061 53.7342L9.33482 66.1612H7.61456L24.0908 52.2712L24.1061 53.7342Z"
      fill="#595C5B"
    />
    <path
      d="M24.0679 48.3982L3.09604 66.161H1.35284L24.1061 46.9199L24.0679 48.3982Z"
      fill="#595C5B"
    />
    <path
      d="M0.297791 63.266V61.78L24.8631 40.8142V42.177L0.297791 63.266Z"
      fill="#595C5B"
    />
    <path
      d="M24.8707 37.2881L0.297791 58.0306V56.5523L24.8707 35.8098V37.2881Z"
      fill="#595C5B"
    />
    <path
      d="M24.9242 32.006L0.297791 52.787V51.3164L24.8631 30.5662L24.9242 32.006Z"
      fill="#595C5B"
    />
    <path
      d="M24.1062 27.4249L0.297791 47.5361V46.3042L24.1062 25.8696V27.4249Z"
      fill="#595C5B"
    />
    <path
      d="M24.1367 22.2046L0.297791 42.3003V40.8374L24.1367 20.7571V22.2046Z"
      fill="#595C5B"
    />
    <path
      d="M24.1062 17.0381L0.297791 37.0723V35.594L24.1062 15.5598V17.0381Z"
      fill="#595C5B"
    />
    <path
      d="M24.1062 11.7176L0.297791 31.8904V30.3659L24.1062 10.2778V11.7176Z"
      fill="#595C5B"
    />
    <path
      d="M24.1062 6.58231L0.297791 26.578V25.092L24.1062 5.00391V6.58231Z"
      fill="#595C5B"
    />
    <path
      d="M24.1062 0.47645V1.13091L0.297791 21.2883V19.8485L23.0511 0.46875L23.8692 0.47645H24.1062Z"
      fill="#595C5B"
    />
    <path
      d="M18.7848 0.422607L0.297791 16.0757V14.6051L16.9346 0.422607H18.7848Z"
      fill="#595C5B"
    />
    <path
      d="M11.2998 0.445801H13.0277L0.297791 10.8479V9.61593"
      fill="#595C5B"
    />
    <path
      d="M6.59776 0.422607L0.297791 5.66598V4.15688L4.77046 0.422607H6.59776Z"
      fill="#595C5B"
    />
    <path
      d="M24.1061 64.1054L21.7054 66.1612H19.9622L24.1061 62.604V64.1054Z"
      fill="#595C5B"
    />
    <path
      d="M41.5458 25.8696H24.1062V42.7393H41.5458V25.8696Z"
      fill="#595C5B"
    />
    <path d="M44 0.430176H24.1062V19.2093H44V0.430176Z" fill="#595C5B" />
  </svg>
)

const SocialButton = ({ href, icon, title }) => (
  <Flex
    as="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    title={title}
    justifyContent="center"
    alignItems="center"
    width={24}
    height={24}
    bg="text"
    css={css({ borderRadius: "50%" })}
    mr={1}
  >
    <Icon
      symbol={icon}
      color="background"
      css={css({ width: "50% !important", height: "50% !important" })}
    />
  </Flex>
)

const CareerCard = ({ name, datePosted, location, link }) => (
  <Flex
    bg="background"
    mb={[3, 4]}
    p={[3, 4]}
    flexDirection={["column", "row"]}
    justifyContent="space-between"
    alignItems="center"
  >
    <Box>
      <H2 children={name} textAlign={["center", "left"]} />
      <H6
        children={moment(datePosted).format("MMMM DD, YYYY") + " | " + location}
        mt={3}
        textAlign={["center", "left"]}
        fontWeight={300}
      />
    </Box>
    <Box mt={[5, 0]}>
      <H3
        as="a"
        href={link}
        rel="noopener noreferrer"
        target="_blank"
        children={`Apply`}
        fontSize={"18px"}
        textAlign={["center", "left"]}
        css={{ textDecoration: "none" }}
      />
    </Box>
  </Flex>
)

function BranchPage({ data }) {
  return (
    <>
      <SEO
        title={data.branch.name + " - Branches - Falk"}
        description={
          "Find the " +
          data.branch.name +
          " branch of Falkbuilt Technologies. Reach out to our team today and schedule a consultation."
        }
        image={data.branch.image && data.branch.image.url}
      />
      <Flex
        minHeight={"100vh"}
        bg="primary"
        justifyContent="center"
        flexDirection="column"
        css={css({
          background:
            data.branch.image &&
            data.branch.image.url &&
            "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(" +
            data.branch.image.url +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
        })}
      >
        <Container pt={[0, 120]}>
          <H1 color="textReverse">
            <span children={`Falkbuilt`} />
            <br />
            <span children={data.branch.name} />
          </H1>
          {/*data.branch.area && (
            <H6
              children={"Serving " + data.branch.area}
              mt={3}
              color="textReverse"
            />
          )*/}
          {data.branch.address && (
            <Box mt={4}>
              <Box
                position="relative"
                display="inline-block"
                mb={[2, 3]}
                py={4}
                pr={4}
                bg="backgroundReverse"
              >
                <Text children={data.branch.address} color="textReverse" />
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  height="100%"
                  bg="backgroundReverse"
                  css={css({
                    transform: "translateX(-100%)",
                    width: "32px",
                    "@media screen and (min-width: 52em)": {
                      width: "64px",
                    },
                    "@media screen and (min-width: 64em)": {
                      width: "128px",
                    },
                    "@media screen and (min-width: 1856px)": {
                      width: "calc(((100vw - 1601px) / 2))",
                    },
                  })}
                />
              </Box>
            </Box>
          )}
          {/* <Button
            children={"Contact us"}
            mt={[4, 5]}
            variant="default"
            as={Link}
            to="/branch-contact/"
          /> */}
        </Container>
      </Flex>
      <Container>
        <Flex flexWrap="wrap" my={[5, 6]}>
          <Box
            width={["100%", null, 1 / 2, 2 / 3]}
            css={css({
              "@media screen and (max-width: 1420px)": {
                width: "100%",
              },
            })}
          >
            <Flex flexWrap="wrap">
              {/* NEW CODE FOR THE BRANCH STAFF */}
              {data.branch.staff &&
                data.branch.staff.map((staffMember, index) => (
                  <Flex
                    flexWrap="wrap"
                    key={"staff" + index}
                    width={["100%", "100%", null, 1 / 2]}
                    mb={[2, 3, 4]}
                  >
                    <Box mr={4} mb={[3, 0]}>
                      <Box
                        position="relative"
                        css={css({
                          height: [96, 64, 128],
                          width: [96, 64, 128],
                          borderRadius: 9999,
                          backgroundImage:
                            staffMember.headshot &&
                            staffMember.headshot.url &&
                            "url(" +
                            staffMember.headshot.url +
                            "?fit=crop&crop=center&max-w=256&max-h=256&auto=compress)",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        })}
                        bg="primary"
                      >
                        {(!staffMember.headshot ||
                          !staffMember.headshot.url) && <EmptyVector />}
                      </Box>
                    </Box>
                    <Flex
                      flexWrap="wrap"
                      flex={["initial", 1]}
                      width={["100%", "initial"]}
                    >
                      <Flex
                        flexDirection="column"
                        width={["100%"]}
                        mb={[5, null, null, 0]}
                      >
                        {staffMember.name && (
                          <Box>
                            <H3 children={staffMember.name} />
                            <Text
                              children={staffMember.role}
                              fontSize={[3, 4]}
                            />
                          </Box>
                        )}
                        <Box mt={4}>
                          {staffMember.phone && (
                            <Text>
                              <a
                                children={staffMember.phone}
                                href={`tel:${staffMember.phone}`}
                                css={css({ textDecoration: "none" })}
                              />
                            </Text>
                          )}
                          {staffMember.email && (
                            <Text css={css({ wordBreak: "break-all" })}>
                              <a
                                children={staffMember.email}
                                href={`mailto:${staffMember.email}`}
                                css={css({ textDecoration: "none" })}
                              />
                            </Text>
                          )}
                        </Box>
                        {(staffMember.linkedin ||
                          staffMember.facebook ||
                          staffMember.twitter) && (
                            <Flex mt={2}>
                              {staffMember.linkedin && (
                                <SocialButton
                                  icon={mdiLinkedin}
                                  title={`LinkedIn`}
                                  href={staffMember.linkedin}
                                />
                              )}
                              {staffMember.twitter && (
                                <SocialButton
                                  icon={mdiTwitter}
                                  title={`Twitter`}
                                  href={staffMember.twitter}
                                />
                              )}

                              {staffMember.facebook && (
                                <SocialButton
                                  icon={mdiFacebook}
                                  title={`Facebook`}
                                  href={staffMember.facebook}
                                />
                              )}
                            </Flex>
                          )}
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
            </Flex>
          </Box>
          {(data.branch.mondayHours ||
            data.branch.tuesdayHours ||
            data.branch.wednesdayHours ||
            data.branch.thursdayHours ||
            data.branch.fridayHours ||
            data.branch.saturdayHours ||
            data.branch.sundayHours) && (
              <Box
                width={["100%", null, null, 1 / 3]}
                css={css({
                  "@media screen and (max-width: 1420px)": {
                    width: "100%",
                  },
                })}
              >
                <H6 children={`Hours`} mb={3} />
                {data.branch.mondayHours && (
                  <Text>
                    <span
                      children={`Monday`}
                      css={css({ display: "inline-block", width: 128 })}
                    />
                    <span children={data.branch.mondayHours} />
                  </Text>
                )}
                {data.branch.tuesdayHours && (
                  <Text>
                    <span
                      children={`Tuesday`}
                      css={css({ display: "inline-block", width: 128 })}
                    />
                    <span children={data.branch.tuesdayHours} />
                  </Text>
                )}
                {data.branch.wednesdayHours && (
                  <Text>
                    <span
                      children={`Wednesday`}
                      css={css({ display: "inline-block", width: 128 })}
                    />
                    <span children={data.branch.wednesdayHours} />
                  </Text>
                )}
                {data.branch.thursdayHours && (
                  <Text>
                    <span
                      children={`Thursday`}
                      css={css({ display: "inline-block", width: 128 })}
                    />
                    <span children={data.branch.thursdayHours} />
                  </Text>
                )}
                {data.branch.fridayHours && (
                  <Text>
                    <span
                      children={`Friday`}
                      css={css({ display: "inline-block", width: 128 })}
                    />
                    <span children={data.branch.fridayHours} />
                  </Text>
                )}
                {data.branch.saturdayHours && (
                  <Text>
                    <span
                      children={`Saturday`}
                      css={css({ display: "inline-block", width: 128 })}
                    />
                    <span children={data.branch.saturdayHours} />
                  </Text>
                )}
                {data.branch.sundayHours && (
                  <Text>
                    <span
                      children={`Sunday`}
                      css={css({ display: "inline-block", width: 128 })}
                    />
                    <span children={data.branch.sundayHours} />
                  </Text>
                )}
              </Box>
            )}
        </Flex>
      </Container>
      {data.branch.branchCallout && (
        <Container>
          <Box mb={[5, 6]}>
            <Box
              position="relative"
              display="inline-block"
              mb={[2, 3]}
              py={[4, 5]}
              pr={[4, 5]}
              bg="primary"
            >
              <Box maxWidth={828}>
                <H2
                  children={"Welcome to Falkbuilt,  " + data.branch.name + "."}
                />
                <Text
                  children={data.branch.branchCallout}
                  mt={[3, 4]}
                  fontFamily="condensed"
                  fontSize={[5, 6]}
                />
              </Box>
              <Box
                position="absolute"
                top={0}
                left={0}
                height="100%"
                bg="primary"
                css={css({
                  transform: "translateX(-100%)",
                  width: "32px",
                  "@media screen and (min-width: 52em)": {
                    width: "64px",
                  },
                  "@media screen and (min-width: 64em)": {
                    width: "128px",
                  },
                  "@media screen and (min-width: 1856px)": {
                    width: "calc(((100vw - 1601px) / 2))",
                  },
                })}
              />
            </Box>
            <Text
              children={`Branch is independently owned and operated.`}
              fontSize={"10px"}
            />
          </Box>
        </Container>
      )}
      <Box bg="muted" py={[5, 6]}>
        <Container>
          <Box width="100%" maxWidth={630} mx="auto">
            <H1 as="h2" children={"Events + news"} textAlign="center" />
            <Text
              children={
                "Check out our blog posts to see what we've been up to."
              }
              mt={[3, 4]}
              fontFamily="condensed"
              fontSize={[5, 6]}
              textAlign="center"
            />
            <Flex justifyContent="center" mt={5}>
              <Button
                children={"Blog"}
                as={Link}
                to={"/blog"}
                variant="default"
                textAlign="center"
              />
            </Flex>
          </Box>
          <Flex pt={5} m={-3} flexWrap="wrap" css={css({ overflow: "hidden" })}>
            {data.posts.nodes &&
              data.posts.nodes.length > 0 &&
              data.posts.nodes.map((post, index) => (
                <PostCard
                  width={["100%", null, 1 / 2]}
                  image={post.image && post.image.url}
                  date={
                    post.postedDate &&
                    moment(post.postedDate).format("MMM DD, YYYY")
                  }
                  title={post.title}
                  subtitle={post.subtitle}
                  link={"/blog/" + post.url + "/"}
                />
              ))}
          </Flex>
        </Container>
      </Box>
      <Box bg="primary" py={[5, 6]}>
        <Container>
          <Box width="100%" maxWidth={630} mx="auto">
            <H1 as="h2" children={data.careers.title} textAlign="center" />
            {data.careers.link &&
              (!data.branch.careers || data.branch.careers.length === 0) && (
                <Flex justifyContent="center" mt={5}>
                  <Button
                    children={"View current openings"}
                    as="a"
                    href={data.careers.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="default"
                  />
                </Flex>
              )}
          </Box>
          {data.branch.careers && (
            <Box py={[5, 6]}>
              {data.branch.careers.map((career, index) => (
                <CareerCard {...career} key={"branchCareer" + index} />
              ))}
            </Box>
          )}
        </Container>
      </Box>
    </>
  )
}

export const query = graphql`
  query BranchQuery($handle: String!) {
    branch: datoCmsBranch(url: { eq: $handle }) {
      url
      branchCallout
      image {
        url
      }
      name
      area
      address
      staff {
        headshot {
          url
        }
        name
        role
        email
        phone
        linkedin
        facebook
        twitter
      }
      careers {
        name
        location
        link
        datePosted
      }
      mondayHours
      tuesdayHours
      wednesdayHours
      thursdayHours
      fridayHours
      saturdayHours
      sundayHours
      coordinates {
        latitude
        longitude
      }
    }
    careers: datoCmsCareersPage {
      title
      subtitle
      link
    }
    posts: allDatoCmsBlogPost(
      filter: { branch: { url: { eq: $handle } } }
      sort: { order: DESC, fields: postedDate }
      limit: 10
    ) {
      nodes {
        url
        image {
          url
        }
        title
        postedDate
        subtitle
      }
    }
  }
`

export default BranchPage
